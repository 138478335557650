export interface IState {
	title: string,
	buttonText: string,
	allPages: ISuggestion[],
	pages: ISuggestion[],
	asterisk: string,
	max: number,
	showEventsLink: boolean,
	eventsLink: string,
	pageType: string,
	currentIndex: number,
	rekai: boolean,
	listPage: {
		name: string,
		url: string
	},
	showListPage: boolean,
	translations: Object,
	lazyLoadPreviewImage: string
}
export interface ISuggestion
{
	id: string,
	template: string,
	title: string,
	image: {
		src: string,
		alt: string,
	},
	location: {
		url: string,
		name: string,
	}
	url: string,
	startDate: string,
	startMonth: string,
	fullStartDate: string,
	fullEndDate: string,
	type: string
}
const deafultState: IState = {
	title: '',
	buttonText: '',
	allPages: [{
		id: '',
		template: '',
		title: '',
		image: {
			src: '',
			alt: ''
		},
		location: {
			url: '',
			name: ''
		},
		url: '',
		startDate: '',
		startMonth: '',
		fullStartDate: '',
		fullEndDate: '',
		type: ''
	}],
	pages: [{
		id: '',
		template: '',
		title: '',
		image: {
			src: '',
			alt: ''
		},
		location: {
			url: '',
			name: ''
		},
		url: '',
		startDate: '',
		startMonth: '',
		fullStartDate: '',
		fullEndDate: '',
		type: ''
	}],
	asterisk: '',
	max: 6,
	showEventsLink: false,
	eventsLink: '',
	pageType: '',
	currentIndex: 0,
	rekai: false,
	listPage: {
		name: '',
		url: ''
	},
	showListPage: false,
	translations: {},
	lazyLoadPreviewImage: ""
}

export default deafultState;
