
import Vue from 'vue'
import { ISuggestion } from '../store/state'

interface SuggestionProps {
	page: ISuggestion,
	count: number,
	max: number,
	pageType: string,
	asterisk: string,
	lazyLoadPreviewImage: string
}

export default Vue.extend<{}, {}, {}, SuggestionProps>({
	name: 'Suggestion',
	props: {
		page: {},
		count: Number,
		max: Number,
		pageType: String,
		asterisk: String,
		lazyLoadPreviewImage: String
	},
	methods: {
		getClass(){
			var base = "hiq-suggestions-list__item"
			if(this.pageType == 'event'){
				return base += "-event";
			}
			if(this.max == 3){
				return "";
			}
			switch(this.count){
				case 0:
					base += "-full";
					break;
				case 1:
					base += "-half";
					break;
				case 2:
					base += "-half";
					break;
				default:
					base += "-third";
					break;
			}
			return base;
		}
	}
})

