
import Vue from 'vue'
import Suggestion from './components/Suggestion.vue';
import HeaderBlock from './components/HeaderBlock.vue';
import { ISuggestion } from './store/state'
import { currentIndex } from './store/actions';
// Interfaces for component typechecking!
interface AppData {
	counter: number,
}

interface AppMethods {

}

interface AppComputed {
	pages: ISuggestion[],
	allPages: ISuggestion[],
	title: string,
	max: number,
	pageType: string,
	asterisk: string,
	canLoadMore: boolean,
	showEventsLink: boolean,
	eventsLink: string,
	showListPage: boolean,
	listPage: {},
	firstThreePages: ISuggestion[],
	remainingPages: ISuggestion[],
	buttonText: string,
	translations: {},
	lazyLoadPreviewImage: string
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	data: {
		counter: 0,
	},
	computed: {
		pages(){
			return this.$store.getters.pages;
		},
		allPages() {
			return this.$store.getters.allPages;
		},
		title() {
			return this.$store.getters.title;
		},
		max() {
			return this.$store.getters.max;
		},
		pageType() {
			return this.$store.getters.pageType;
		},
		asterisk() {
			return this.$store.getters.asterisk;
		},
		canLoadMore() {
			return this.allPages.length > this.pages.length ? true : false;
		},
		showEventsLink() {
			return this.$store.getters.showEventsLink;
		},
		eventsLink(){
			return this.$store.getters.eventsLink;
		},
		showListPage(){
			return this.$store.getters.showListPage;
		},
		listPage() {
			return this.$store.getters.listPage;
		},
		firstThreePages(){
			return this.pages.slice(0,3);
		},
		remainingPages(){
			return this.pageType == 'event' ? this.pages : this.pages.slice(3, this.pages.length);
		},
		buttonText(){
			return this.$store.getters.buttonText;
		},
		translations() {
			return this.$store.state.translations;
		},
		lazyLoadPreviewImage() {
			return this.$store.getters.lazyLoadPreviewImage;
		}
	},
	methods: {
		loadMore(){
			this.$store.dispatch('currentIndex', this.pages.length)
			this.$store.dispatch('loadMore');
		}
	},
	components: {
		Suggestion,
		HeaderBlock
	}, 
})
