import { IState, ISuggestion } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';

type PayloadType = 'initApp' | 'setState' | 'loadMore' | 'setCurrentIndex'
export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;

export interface ILoadMorePayLoad {
	pages: Array<ISuggestion>
}

// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})

export const loadMore: ActionCreator<undefined> = (state) => ({
	type: 'loadMore',
	payload: state
})

export const currentIndex: ActionCreator<number> = (state) => ({
	type: 'setCurrentIndex',
	payload: state
})


// Action handlers
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
}

const loadMoreAction: ActionHandler<ILoadMorePayLoad> = ({ commit, state }) => {
	const currIndex = state.currentIndex;
	const newIndex = currIndex + state.max;
	const appendList = state.allPages.slice(currIndex, newIndex);
	const pages = state.pages;
	appendList.forEach((page) => {
		pages.push(page)
	});
	commit({
		type: 'setCurrentIndex',
		payload: newIndex,
	})
	commit({
		type: 'loadMore',
		payload: pages,
	})
}
const setCurrentIndexAction: ActionHandler<number> = ({ commit }, payload) => {
	commit({
		type: 'setCurrentIndex',
		payload
	})
}

const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	loadMore: loadMoreAction,
	currentIndex: setCurrentIndexAction,
}

export default actions;
