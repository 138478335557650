
import Vue from 'vue'
import { ISuggestion } from '../store/state'

interface HeaderProps {
	title: String,
	asterisk: String,
	pageType: String,
	showListPage: Boolean,
	listPage: {},
	translations: {}
}

export default Vue.extend<{}, {}, {}, HeaderProps>({
	name: 'HeaderBlock',
	props: {
		title: String,
		asterisk: String,
		pageType: String,
		showListPage: Boolean,
		listPage: {},
		translations: {},
	},
	computed: {
		arrayTitle() {
			return this.title.split("\n");
		},
	}, 
	methods: {
		getAsteriskClasses(){
			var base = "hiq-section-asterisk--";
			var color = base + this.asterisk;
			var placing = this.pageType == 'event' ? base + 'left' : base + 'right';
			return placing + " " + color;
		}
	}
})
