import { MutationTree, Mutation } from 'vuex';
import { IState, ISuggestion } from './state';
import { ActionPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}

const loadMoreMutation: MutationHandler<ISuggestion[]> = (state, { payload }) => {
	state.pages = payload;
}
const setCurrentIndexMutation: MutationHandler<number> = (state, { payload }) => {
	state.currentIndex = payload;
}

const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	loadMore: loadMoreMutation,
	setCurrentIndex: setCurrentIndexMutation,
}

export default mutations
