import { Getter } from "vuex";
import { IState } from './state';

const allPages: Getter<IState, IState> = (state) => state.allPages;
const pages: Getter<IState, IState> = (state) => state.pages;
const title: Getter<IState, IState> = (state) => state.title;
const max: Getter<IState, IState> = (state) => state.max;
const pageType: Getter<IState, IState> = (state) => state.pageType;
const asterisk: Getter<IState, IState> = (state) => state.asterisk;
const showEventsLink: Getter<IState, IState> = (state) => state.showEventsLink;
const eventsLink: Getter<IState, IState> = (state) => state.eventsLink;
const currentIndex: Getter<IState, IState> = (state) => state.currentIndex;
const listPage: Getter<IState, IState> = (state) => state.listPage;
const showListPage: Getter<IState, IState> = (state) => state.showListPage;
const buttonText: Getter<IState, IState> = (state) => state.buttonText;
const lazyLoadPreviewImage: Getter<IState, IState> = (state) => state.lazyLoadPreviewImage;

export default {
	allPages,
	pages,
	title,
	max,
	pageType,
	showEventsLink,
	eventsLink,
	asterisk,
	currentIndex,
	showListPage,
	listPage,
	buttonText,
	lazyLoadPreviewImage
}
